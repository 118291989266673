<template>
    <div class="locale-switcher flex-shrink-0">
        <!-- <b-form-select v-model="$i18n.locale" @change="localeChanged" class="">
            <b-form-select-option :value="locale.code" v-for="locale in locales" :key="locale.code">
                {{ locale.name }}
            </b-form-select-option>
        </b-form-select> -->

        <v-select :options="locales" label="name" v-model="$i18n.locale" :clearable="false" :reduce="locale => locale.code"
            @change="localeChanged" class="flex-shrink-0" style="min-width: 8rem;"></v-select>
    </div>
</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales"
export default {
    data: () => ({ locales: getSupportedLocales() }),
    methods: {
        localeChanged() {
            document.querySelector("html").setAttribute('lang', this.$i18n.locale);
            if (this.$i18n.locale == 'ar') {
                document.querySelector("html").setAttribute('dir', 'rtl');
            } else {
                document.querySelector("html").setAttribute('dir', 'ltr');
            }
        }
    }
}
</script>